import { graphql, Link, navigate } from "gatsby"
import React from "react"
import Layout from "../components/layout"

export default function Blog({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  return (
    <Layout>
      <section className="m-y-3">
        <section className="bordered text-center m-b-2">
          <h1>Health & Wellness Blog</h1>
        </section>
        <div className="flex flex-wrap justify-content-center-space-between halves">
          {edges.map(edge => {
            const style = {
              backgroundImage: `url(${edge.node.frontmatter.thumbnail})`
            };
            return (
              <button className="blog-thumb m-b-2" key={edge.node.id} style={style} onClick={() => navigate(edge.node.frontmatter.slug)}>
                <span>{edge.node.frontmatter.title}</span>
              </button>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            thumbnail
          }
        }
      }
    }
  }
`